<template>
  <section class="box-height perfromanceTable" style="padding: 0 15px">
    <div
      class="labelLibrary"
      style="background-color: #fff; padding-bottom: 30px"
    >
      <div class="searchM">
        <el-form
          :model="filter"
          ref="filter"
          style="background-color: #fff"
          label-width="90px"
          class="clearfix"
        >
          <div class="clearfix sechContent" style="padding: 15px 0px 0">
            <el-form-item label="标签名称：" prop="labelName">
              <el-input
                style="width: 240px"
                v-model="filter.labelName"
                placeholder="请输入标签名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="创建顾问：" prop="consultantIds">
              <el-cascader
                class="searchCascader"
                style="min-width: 240px"
                filterable
                clearable
                :options="options2"
                collapse-tags
                :show-all-levels="false"
                v-model="consultantIds"
                :props="props"
                placeholder="请选择小类"
                ref="dataRef"
                @change="getSearch"
                :filter-method="filterFun"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label-width="0">
              <el-button
                style="margin-left: 15px; height: 32px"
                type="primary"
                size="mini"
                @click="getData"
                >搜索</el-button
              >
            </el-form-item>
          </div>
          <el-col :span="24">
            <el-pagination
              :hide-on-single-page="filter.page.total > 10 ? false : true"
              v-if="filter.page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="filter.page.current"
              :page-size="filter.page.size"
              :pager-count="5"
              layout="total,prev, pager, next,sizes"
              :total="filter.page.total"
              :page-sizes="[10, 20, 50, 100]"
            ></el-pagination>
          </el-col>
        </el-form>
      </div>
      <div
        class="pd40"
        style="background-color: #fff; padding-top: 7px; padding-bottom: 20px"
      >
        <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
            'border-top': '1px #e5e5e5 solid',
          }"
          class="tabBorder custor"
          style="width: 100%; margin: 0px; border-top: 0"
        >
          <el-table-column prop="name" min-width="150" label="标签名称">
          </el-table-column>
          <el-table-column prop="creatorName" min-width="150" label="创建人">
          </el-table-column>
          <el-table-column prop="createdTime" label="创建时间" min-width="150">
          </el-table-column>
          <el-table-column prop="hits" min-width="150" label="命中数量">
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="left" width="180">
            <template slot-scope="scope">
              <span
                class="tabHref"
                size="mini"
                @click="deletePersonalizedTag(scope.row)"
                >删除
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
          style="padding: 10px 15px 20px"
        ></el-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import {
  labelCustomDelete, //删除个性化标签
  labelCustomPage, //个性化标签分页
  treeList, //创建顾问
} from "../../api/api";
export default {
  data() {
    return {
      filter: {
        consultantIds: [],
        labelName: "",
        page: {
          total: 0,
          current: 1,
          size: 50,
        },
      },
      ListData: [],
      options2: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      consultantIds: [],
    };
  },
  created() {
    this.permissionTree();
    this.loadInfolist();
  },
  methods: {
    // 创建顾问
    permissionTree() {
      treeList().then((res) => {
        if (res.success) {
          this.options2 = res.result;
        }
      });
    },
    getSearch(val) {
      const _nodeId = this.$refs.dataRef.getCheckedNodes(true);
      // let isChecked;
      this.filter.consultantIds = this.consultantIds.map((i) => {
        return i[i.length - 1];
      });
      this.getData();
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    //列表搜索
    getData() {
      this.filter.page.current = 1;
      this.filter.page.size = 50;
      this.loadInfolist();
    },

    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadInfolist();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.filter.page.current = val;
      this.loadInfolist();
    },

    //获取列表数据
    loadInfolist() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      labelCustomPage(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //删除个性化标签
    deletePersonalizedTag(data) {
      let isDelete = false;
      if (!data.hits) {
        isDelete = true;
      } else {
        let div =
          "<p style='font-weight:bold'>确定删除？ " +
          "<p>人选将同步解除标签绑定关系</p>";
        this.$confirm(div, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "altCls",
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          dangerouslyUseHTMLString: true,
        })
          .then(() => {
            // isDelete = true;
            labelCustomDelete(data.id).then((res) => {
              if (res.success) {
                this.$message.success("操作成功");
                this.loadInfolist();
              }
            });
          })
          .catch(() => {
            isDelete = false;
          });
      }
      if (isDelete) {
        labelCustomDelete(data.id).then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
            this.loadInfolist();
          }
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
    margin-bottom: 15px;
  }
}
.istypeSelect {
  .el-select-dropdown__item {
    padding-right: 35px;
  }
}
</style>